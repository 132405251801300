import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import { type MouseEventHandler, useState, ComponentProps, FC, useEffect, useCallback } from "react";

import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "@rimo/ui-old";
import { PricingItem, PricingItemProps } from "./PricingItem";
import { PricingPaymentGroup } from "./PricingPaymentGroup";
import { useResponsive } from "@rimo/frontend/hooks/useResponsive";
import { User } from "firebase/auth";
import { BillingCycle, Grade, InterviewUsage } from "@rimo/frontend/types/subscription";

import { useBackend } from "@rimo/frontend/hooks/useBackend";
import { getGradeFromProductName, getGradeString, redirectCheckoutSession } from "@rimo/frontend/utils/subscription";
import { SubscriptionModal } from "@rimo/frontend/components/interviews/SubscriptionModal";
import { FeatherIcon } from "@rimo/frontend/components/ui/FeatherIcon";
import { ChevronRight } from "@styled-icons/feather/ChevronRight";

const planFreeName = "Free";
const planBasicName = "Basic";
const planProName = "Pro";

export const freeDurationLimit = 40;
export const basicDurationLimit = 90;
export const proDurationLimit = 90;

export const getGradeDurationLimit = (grade: Grade): number => {
  switch (grade) {
    case "free":
      return freeDurationLimit * 60 * 1000;
    case "basic":
      return basicDurationLimit * 60 * 1000;
    case "pro":
      return proDurationLimit * 60 * 1000;
    default:
      return Infinity;
  }
};
const calculateAnnuallyPrice = (price: number): number => Math.floor(price * 12);

export const getPricingItemProps = (
  paymentMethod: string,
  selectedPlan: string | null,
  isMobile: boolean
): [PricingItemProps, PricingItemProps, PricingItemProps] => {
  const isMonthlyPayment = paymentMethod === "monthly";
  const priceFree = 0;
  const priceBasic = isMonthlyPayment ? 15 : 12;
  const pricePro = isMonthlyPayment ? 30 : 24;
  const props: [PricingItemProps, PricingItemProps, PricingItemProps] = [
    {
      name: planFreeName,
      price: priceFree,
      selected: selectedPlan === planFreeName,
      helperText: "No credit card required",
      features: [
        "1 article / month",
        "Up to 4 AI Edit / month",
        `Up to ${freeDurationLimit} minutes of audio per file. `,
      ],
      simplifiedFeatures: ["1 articles / month", "4 AI Edits / month"],

      fadeHelperText: false,
    },
    {
      name: planBasicName,
      price: priceBasic,
      selected: selectedPlan === planBasicName,
      popular: !isMobile,
      helperText: isMonthlyPayment ? null : `Billed $${calculateAnnuallyPrice(priceBasic)} annually`,
      features: [`5 articles / month`, "20 AI edits / month", `Up to ${basicDurationLimit} minutes of audio per file.`],

      simplifiedFeatures: ["5 articles / month", "20 AI edits / month"],

      fadeHelperText: isMonthlyPayment,
    },
    {
      name: planProName,
      price: pricePro,
      selected: selectedPlan === planProName,
      helperText: isMonthlyPayment ? null : `Billed $${calculateAnnuallyPrice(pricePro)} annually`,
      features: [`15 articles / month`, "60 AI edits / month", `Up to ${proDurationLimit} minutes of audio per file.`],

      simplifiedFeatures: ["15 articles / month", "60 AI edits / month"],

      fadeHelperText: isMonthlyPayment,
    },
  ];

  return props;
};

type Products = { [key in BillingCycle]: { [grade in Grade]: boolean } };

export const PricingSection: FC<{ user?: User }> = ({ user }) => {
  return (
    <Container>
      <PricingHeader>
        <PricingTitle>Pricing</PricingTitle>
      </PricingHeader>
      <PricingTable user={user} />
    </Container>
  );
};

export const PricingTable: FC<{ user?: User }> = ({ user }) => {
  const { isMobile } = useResponsive();
  const [billingCycle, setBillingCycle] = useState<BillingCycle>("monthly");
  const [plan, setPlan] = useState<string | null>(planFreeName);
  const props = getPricingItemProps(billingCycle, plan, isMobile);
  const onClick: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.preventDefault();
    const next = e.currentTarget.value;
    setPlan((prev) => {
      if (prev === next) {
        return null;
      }
      return next;
    });
  };

  const serviceId = process.env.NEXT_PUBLIC_AIEDITOR_SERVICE_ID ?? "";
  const [products, setProducts] = useState<Products>({
    monthly: { free: false, basic: false, pro: false, unlimited: false },
    yearly: { free: false, basic: false, pro: false, unlimited: false },
  });
  const { data: usage } = useBackend<InterviewUsage>(user ? `/interview_usage` : null);
  const userGrade = usage && usage.product_name ? getGradeFromProductName(usage.product_name) : "free";
  const productName = userGrade === "free" ? "aieditor_free_monthly" : usage?.product_name;

  const [selectedGrade, setSelectedGrade] = useState<Grade | null>(null);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (usage) {
      if (userGrade === "unlimited") {
        setProducts((prev) => {
          const newState = { ...prev };
          newState.monthly.unlimited = newState.yearly.unlimited = true;
          newState.monthly.free = newState.yearly.free = false;
          return newState;
        });
      } else if (productName) {
        const [_, grade, cycle] = productName.split("_") as [string, Grade, BillingCycle];
        setProducts((prev) => {
          const newState = { ...prev };
          newState.monthly.free = newState.yearly.free = false;
          newState[cycle as BillingCycle][grade] = true;
          return newState;
        });
      }
    }
  }, [usage, userGrade, productName]);

  const handlePricingButton = useCallback(
    (disable: boolean, isCurrentPlan: boolean, grade: Grade) => {
      if (disable) return;

      const isFreePlan = grade === "free";

      switch (userGrade) {
        case "free":
          if (!isCurrentPlan && !isFreePlan) redirectCheckoutSession(serviceId, billingCycle, grade);
          break;
        case "basic":
        case "pro":
          setSelectedGrade(grade);
          setIsOpenModal(true);
          break;
        default:
          break;
      }
    },
    [userGrade, billingCycle, serviceId]
  );

  return (
    <PricingTableWrapper>
      <PricingHeader>
        <PricingPaymentGroup value={billingCycle} onToggle={setBillingCycle} />
      </PricingHeader>
      <GridContainer id="pricing">
        <GridCardContainer $count={3}>
          {Object.keys(products[billingCycle]).map((gradeKey, i) => {
            const grade = gradeKey as Grade;
            const isCurrentPlan = products[billingCycle][grade] || (userGrade === "free" && grade === "free");
            const isFreePlan = grade === "free";
            const disable = isCurrentPlan || isFreePlan;

            if (grade === "unlimited") return null;

            return (
              <PricingItem
                {...props[i]}
                disable={disable}
                button={
                  usage ? (
                    <PricingButton
                      buttonType={isCurrentPlan ? "current" : "subscribe"}
                      disable={disable}
                      onClick={() => handlePricingButton(disable, isCurrentPlan, grade)}
                    />
                  ) : null
                }
                onClick={onClick}
                key={i}
                isLP={user === undefined}
              />
            );
          })}
        </GridCardContainer>
      </GridContainer>
      <SubscriptionModal
        initialPage={1}
        initialCycle={billingCycle}
        initialPlan={getGradeString(selectedGrade ?? "basic")}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      />
    </PricingTableWrapper>
  );
};

const PricingTableWrapper = styled.div`
  margin-top: 1.5rem;

  > :nth-child(2) {
    margin-top: 1.5rem;
  }
`;

const Container = styled.div`
  > :nth-child(1) {
    margin-top: 8rem;
  }
  > :nth-child(2) {
    margin-top: 1.5rem;
  }
`;

const PricingHeader = styled.div`
  margin: 0 auto;
  width: fit-content;
`;

const PricingTitle = styled.h2`
  display: inline;
  font-family: "Helvetica Neue";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(224.79deg, #e50000 -0.12%, #ffaf00 99.9%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const GridContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: auto;
  grid-row-gap: 18px;
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    grid-template-columns: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 0.5rem;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    grid-template-columns: 1fr;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`;

const GridCardContainer = styled.div<{ $count: number }>`
  display: grid;
  grid-column-gap: 32px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
    > :nth-child(1) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    > :nth-child(2) {
      border-radius: 0;
    }
    > :nth-child(3) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
    grid-row-gap: 18px;
  }
`;

const PricingButton = ({
  buttonType,
  disable,
  onClick,
}: ComponentProps<typeof BaseButton> & {
  buttonType?: "current" | "subscribe";
  disable: boolean;
  onClick: () => void;
}) => {
  const { customT } = useClientTranslation();

  const isCurrentPlan = buttonType === "current";
  const text = isCurrentPlan ? "Current Plan" : "Subscribe Now";

  return (
    <BaseButton disable={disable} onClick={onClick}>
      {customT(text)}
      {!isCurrentPlan && <FeatherIcon Icon={ChevronRight} color="currentColor" size={24} strokeWidth={2} />}
    </BaseButton>
  );
};

const BaseButton = styled.button<{ disable: boolean }>`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 10px 32px 10px 32px;
  border-radius: 180px;
  background: ${({ disable }) => (disable ? "#ddd" : "rgba(241, 76, 0, 1)")};
  color: ${({ disable }) => (disable ? "rgba(102, 102, 102, 1)" : "#fff")};
  border: none;
  cursor: ${({ disable }) => (disable ? "normal" : "pointer")};

  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  transition: 0.3s;
`;
